import React from 'react';
import { PageProps, graphql } from 'gatsby';
import { FormattedMessage, useIntl } from 'gatsby-plugin-intl';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { renderRichText, RenderRichTextData } from 'gatsby-source-contentful/rich-text';

import ArrowButton from '../components/arrow-button';
import Breadcrumbs from '../components/breadcrumbs';
import Card from '../components/card';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Section from '../components/section';
import SocialMediaButtons from '../components/social-media-button/social-media-buttons';

interface BaseService {
  id: string;
  name: string;
  serviceId: string;
  subtitle: RenderRichTextData<any>;
  shortDescription: RenderRichTextData<any>;
  featuredImage: {
    description: string;
    localFile: {
      childImageSharp: {
        card: IGatsbyImageData;
        hero: IGatsbyImageData;
      };
    };
  };
}

interface Service extends BaseService {
  slogan: string;
  description: RenderRichTextData<any>;
  serviceLogotypeDark: {
    description: string;
    localFile: {
      publicURL: string;
    };
  };
  serviceLogotypeLight: {
    description: string;
    localFile: {
      publicURL: string;
    };
  };
}

interface DataProps {
  otherServices: {
    edges: {
      service: Service;
    }[];
  };
  serviceId: string;
  service: Service;
}

interface PageContextType {
  serviceId: string;
}

const ServiceTemplate: React.FC<PageProps<DataProps, PageContextType>> = (props) => {
  const {
    data: { service, otherServices },
    location,
    pageContext: { serviceId },
  } = props;

  const intl = useIntl();

  return (
    <>
      <SEO title={`${service.slogan} - ${service.name}`} />
      <Layout
        breadcrumbs={
          <Breadcrumbs
            items={[
              { to: '/', title: intl.formatMessage({ id: 'Start', defaultMessage: 'Start' }) },
              {
                to: '/services',
                title: intl.formatMessage({ id: 'Services', defaultMessage: 'Services' }),
              },
              { to: `/services/${serviceId}`, title: service.name },
            ]}
          />
        }
        path={location.pathname}
        image={
          <GatsbyImage
            alt={service.featuredImage.description}
            image={service.featuredImage.localFile.childImageSharp.hero}
            className="h-56 w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-full"
            draggable={false}
          />
        }
        title={
          <>
            <img
              alt={service.name}
              src={service.serviceLogotypeDark.localFile.publicURL}
              draggable={false}
              className="h-32 md:h-40 mx-auto md:mx-0 dark:hidden"
            />
            <img
              alt={service.name}
              src={service.serviceLogotypeLight.localFile.publicURL}
              draggable={false}
              className="h-32 md:h-40 mx-auto md:mx-0 hidden dark:block"
            />
          </>
        }
        subTitle={service.subtitle ? renderRichText(service.subtitle) : service.slogan}
      >
        <Section id={serviceId} title={service.name}>
          <div className="mx-auto mt-4 leading-relaxed px-4 md:px-8 max-w-prose prose-xl">
            {renderRichText(service.description)}
          </div>
        </Section>

        {
          otherServices?.edges.length > 0 && (
            <Section
              id="other-services"
              title={intl.formatMessage({ id: 'More services', defaultMessage: 'More services' })}
            >
              <div className="mx-auto mt-4 leading-relaxed px-4 md:px-48 lg:px-48 grid gap-8 grid-col-1 md:grid-cols-2">
                {otherServices.edges.map(({ service }) => (
                  <Card
                    key={service.id}
                    title={service.name}
                    image={
                      <GatsbyImage
                        alt={service.featuredImage.description}
                        image={service.featuredImage.localFile.childImageSharp.card}
                        draggable={false}
                        className="w-full"
                      />
                    }
                    footer={
                      <div className="text-right">
                        <ArrowButton
                          href={`/services/${service.serviceId}`}
                          small={true}
                          type="internal"
                          variant="tertiary"
                        >
                          <FormattedMessage
                            id="Learn more about {topic}"
                            defaultMessage="Learn more about {topic}"
                            values={{
                              topic: service.name,
                            }}
                          />
                        </ArrowButton>
                      </div>
                    }
                  >
                    {renderRichText(service.subtitle)}
                  </Card>
                ))}
              </div>
            </Section>
          )
        }

        <Section
          id="contact"
          title={intl.formatMessage({
            id: 'Contact',
            defaultMessage: 'Contact',
          })}
        >
          <SocialMediaButtons />
        </Section>
      </Layout>
    </>
  );
};

export default ServiceTemplate;

export const query = graphql`
  query ServiceTemplate($serviceId: String, $locale: String) {
    service: contentfulService(serviceId: { eq: $serviceId }, node_locale: { eq: $locale }) {
      id
      slogan
      subtitle {
        raw
      }
      shortDescription {
        raw
      }
      name
      description {
        raw
      }
      featuredImage {
        description
        localFile {
          childImageSharp {
            card: gatsbyImageData(width: 500, height: 400, placeholder: BLURRED)
            hero: gatsbyImageData(width: 1920, height: 1000, placeholder: BLURRED)
          }
        }
      }
      serviceLogotypeDark {
        description
        localFile {
          publicURL
        }
      }
      serviceLogotypeLight {
        description
        localFile {
          publicURL
        }
      }
    }
    otherServices: allContentfulService(
      filter: {serviceId: { ne: $serviceId }, node_locale: { eq: $locale }},
      limit: 2
    ) {
      edges {
        service: node {
          id
          name
          serviceId
          subtitle {
            raw
          }
          shortDescription {
            raw
          }
          featuredImage {
            description
            localFile {
              childImageSharp {
                card: gatsbyImageData(width: 500, height: 400, placeholder: BLURRED)
                hero: gatsbyImageData(width: 1920, height: 1000, placeholder: BLURRED)
              }
            }
          }
        }
      }
    }
  }
`;
